export class SoapUtils {
  static parseNode2(node: Element): Record<string, any> {
    // console.log(`localName: ${node.localName} - nodeType: ${node.nodeType} - childNodes: ${node.childNodes.length} - nodeValue: ${node.nodeValue}`);
    const list_type_check = ["ITEMS", "ROWS", "STAT_LIST", "LIST"];
    var result: Record<string, any> = {};
    if (node.childNodes.length === 1 && node.childNodes[0].nodeType === 3) {
      result[node.localName] = node.childNodes[0].nodeValue as string;
    } else {
      var prev_name: string = "";
      for (let i = 0; i < node.childNodes.length; i++) {
        const childNode = node.childNodes[i] as Element;

        if (childNode.nodeType === 1) {
          const sub_result = this.parseNode(childNode);
          if (result[node.localName] === undefined) {
            if (list_type_check.includes(node.localName)) {
              result[node.localName] = [sub_result];
            } else result[node.localName] = sub_result;
          } else if (prev_name === childNode.localName) {
            if (Array.isArray(result[node.localName][childNode.localName])) {
              result[node.localName][childNode.localName].push(sub_result[childNode.localName]);
            } else {
              result[node.localName][childNode.localName] = [result[node.localName][childNode.localName], sub_result[childNode.localName]];
            }
          } else {
            result[node.localName] = {
              ...result[node.localName],
              ...sub_result,
            };
          }
          prev_name = childNode.localName;
        }
      }
    }
    return result;
  }
  static parseNode(node: Element): Record<string, any> {
    // console.log(`localName: ${node.localName} - nodeType: ${node.nodeType} - childNodes: ${node.childNodes.length} - nodeValue: ${node.nodeValue}`);
    const list_type_check = ["ITEMS", "ROWS", "STAT_LIST", "LIST"];
    var result: Record<string, any> = {};
    if (node.childNodes.length === 1 && node.childNodes[0].nodeType === 3) {
      result[node.localName] = node.childNodes[0].nodeValue as string;
    } else {
      var prev_name: string = "";
      for (let i = 0; i < node.childNodes.length; i++) {
        const childNode = node.childNodes[i] as Element;

        if (childNode.nodeType === 1) {
          const sub_result = this.parseNode(childNode);
          if (result[node.localName] === undefined) {
            if (list_type_check.includes(node.localName)) {
              result[node.localName] = [sub_result];
            } else result[node.localName] = sub_result;
          } else if (prev_name === childNode.localName) {
            if (Array.isArray(result[node.localName])) {
              result[node.localName].push(sub_result);
            } else {
              result[node.localName] = [result[node.localName], sub_result];
            }
          } else {
            result[node.localName] = {
              ...result[node.localName],
              ...sub_result,
            };
          }
          prev_name = childNode.localName;
        }
      }
    }
    return result;
  }
  static parseXmlString(xmlString: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const obj = this.parseNode(xmlDoc.documentElement);
    return obj;
  }

  static parseXmlString2(xmlString: string): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");
    const obj = this.parseNode2(xmlDoc.documentElement);
    return obj;
  }

  static JStoXML(obj: any): string {
    var xml = "";
    for (var prop in obj) {
      xml += obj[prop] instanceof Array ? "" : "<" + prop + ">";
      if (obj[prop] instanceof Array) {
        for (var array in obj[prop]) {
          xml += "<" + prop + ">";
          xml += SoapUtils.JStoXML(new Object(obj[prop][array]));
          xml += "</" + prop + ">";
        }
      } else if (typeof obj[prop] == "object") {
        xml += SoapUtils.JStoXML(new Object(obj[prop]));
      } else {
        xml += obj[prop];
      }
      xml += obj[prop] instanceof Array ? "" : "</" + prop + ">";
    }
    xml = xml.replace(/<\/?[0-9]{1,}>/g, "");
    return xml;
  }
}
