import { Project } from "./Folder";
import { Project as InputProject} from "./GetProjectResponse";

export function generateUpdateProject(input_project:InputProject):Project{
    return {
        aclh:                        input_project.aclh,
    archived:                        input_project.archived,
    comment:                        input_project.comment,
    created:                        input_project.created,
    createdByName:                  input_project.createdByName,
    customer:                       input_project.customer,
    customerName:                   input_project.customerName,
    customState:                    input_project.customState,
    defaultMessageContainer:        input_project.defaultMessageContainer,
    defaultMessageContainerPath:    input_project.defaultMessageContainerPath,
    defaultMessageGroup:            input_project.defaultMessageGroup,
    dimension1:                     input_project.dimension1,
    dimension1Name:                 input_project.dimension1Name,
    dimension2:                     input_project.dimension2,
    dimension2Name:                 input_project.dimension2Name,
    dimension3:                     input_project.dimension3,
    dimension3Name:                 input_project.dimension3Name,
    folder:                         input_project.folder,
    folderName:                     input_project.folderName,
    folderTypeName:                 input_project.folderTypeName,
    formId:                         input_project.formId,
    formName:                       input_project.formName,
    formValues:                     input_project.formValues,
    guiAction:                      input_project.guiAction,
    id:                             input_project.id,
    internalFolder:                 input_project.internalFolder,
    invoiceJournal:                 input_project.invoiceJournal,
    invoiceJournalName:             input_project.invoiceJournalName,
    isMasterProject:                input_project.isMasterProject,
    firstContactDate:               input_project.firstContactDate,
    lastContactDate:                input_project.lastContactDate,
    lastDocDate:                    input_project.lastDocDate,
    masterFolderId:                 input_project.masterFolderId,
    masterFolderName:               input_project.masterFolderName,
    masterFolderTypeName:           input_project.masterFolderTypeName,
    masterProjectId:                input_project.masterProjectId,
    masterProjectName:              input_project.masterProjectName,
    masterProjectTypeName:          input_project.masterProjectTypeName,
    modified:                       input_project.modified,
    modifiedByName:                 input_project.modifiedByName,
    name:                           input_project.name,
    nameInMaster:                   input_project.nameInMaster,
    pricing:                        input_project.pricing,
    pricingName:                    input_project.pricingName,
    projectType:                    input_project.projectType,
    provider:                       input_project.provider,
    providerName:                   input_project.providerName,
    refId:                          input_project.refId,
    state:                          input_project.state,
    typeFormId:                     input_project.typeFormId,
    priority:                       input_project.priority,
    rtsProjectHash:                 input_project.rtsProjectHash,
    sla:                            input_project.sla,
    itemPricing:                    input_project.itemPricing,
    itemPricingName:                input_project.itemPricingName,
    trackingCode:                   input_project.trackingCode
    };
}